import * as Yup from 'yup';
import { DESTINATION_TYPES } from '../../../../../../app/nodes/destination-type/model';
import { DestinationConfigMetaData } from '../interface';
import { SNOWFLAKE_HOST_URL_REGEX } from './constants';
import { getSnowflakeValidationErrorMessage } from './error-message';
import SnowFlake from './index';

function checkFormikStateForError(siblingPath: string) {
  return {
    message: `test-${siblingPath}`,
    name: 'test-sibling-state',
    test() {
      if (this.parent?.[siblingPath]) {
        return this.createError({
          message: this.parent?.[siblingPath]
        });
      }
      return true;
    }
  };
}

function validateCreatableDropdownFieldValue(path, validateField) {
  return {
    message: `${path}-creatableFieldValue`,
    name: `test-dropdown-${path}`,
    test(value) {
      if (!this.parent?.[`${path}-dropdownInCreatableMode`]) {
        return true;
      }

      if (!value) {
        return false;
      }

      return validateField(value);
    }
  };
}

export const SNOWFLAKE_CONFIG: DestinationConfigMetaData = {
  formComponent: SnowFlake,
  initialValues: {
    accountUrl: '',
    accountName: '',
    region: null,
    user: '',
    password: '',
    warehouseName: '',
    databaseName: '',
    schemaName: '',
    loadedAt: DESTINATION_TYPES.SNOWFLAKE.populateLoadedAt,
    isTableTypeTransient: false,
    sanitizeName: false
  },
  validationSchema: Yup.object({
    accountUrl: Yup.string()
      .trim()
      .required()
      .test(checkFormikStateForError('invalidAccountUrl'))
      .matches(SNOWFLAKE_HOST_URL_REGEX, {
        excludeEmptyString: true,
        message: () => getSnowflakeValidationErrorMessage()
      }),
    accountName: Yup.string().trim().required(),
    region: Yup.string().nullable(),
    user: Yup.string().trim().required().test(checkFormikStateForError('invalidUserName')),
    password: Yup.string().trim().required().test(checkFormikStateForError('invalidPassword')),
    'warehouse-creatableFieldValue': Yup.string()
      .nullable()
      .test(
        validateCreatableDropdownFieldValue(
          'warehouse',
          warehouse =>
            warehouse.length > 0 &&
            warehouse.length < 100 &&
            /^[a-zA-Z][a-zA-Z0-9$_]*$/g.test(warehouse)
        )
      ),
    warehouseName: Yup.string().trim().required(),
    'database-creatableFieldValue': Yup.string()
      .nullable()
      .test(
        validateCreatableDropdownFieldValue(
          'database',
          database =>
            database.length > 0 &&
            database.length < 100 &&
            /^[a-zA-Z][a-zA-Z0-9$_]*$/g.test(database)
        )
      ),
    databaseName: Yup.string().trim().required(),
    schemaName: Yup.string().trim().required()
  }),
  fieldNameMapping: {
    accountName: 'account-name',
    region: 'region',
    user: 'db-user',
    password: 'db-password',
    warehouseName: 'warehouse',
    databaseName: 'db-name',
    schemaName: 'schema-name',
    loadedAt: 'loaded-at',
    isTableTypeTransient: 'is-table-type-transient',
    sanitizeName: 'Sanitize Table/Column Names'
  }
};
