import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { HdA11yModule } from '../a11y/a11y.module';
import { BadgeModule } from '../badge/badge.module';
import { OwlDateTimeModule } from '../date-time';
import { OwlMomentDateTimeModule } from '../date-time/adapter/moment-adapter/moment-date-time.module';
import { HdAlertModule } from '../hd-alert/hd-alert.module';
import { HdFormModule } from '../hd-form/hd-form.module';
import { IpModule } from '../ip/ip.module';
import { LoaderModule } from '../loader/loader.module';
import { HdMomentModule } from '../moment/moment.module';
import { PipeModule } from '../pipe/pipe.module';
import { PopupModule } from '../popup/popup.module';
import { SharedModule } from '../shared/shared.module';
import { SidelineModule } from '../sideline/sideline.module';
import { MatSlideToggleModule } from '../slide-toggle/slide-toggle-module';
import { SnackbarModule } from '../snackbar/snackbar.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { TrackingModule } from '../tracking/tracking.module';

import { AddMemberDialogComponent } from './add-member-dialog/add-member-dialog.component';
import { AddPrimaryKeyDialogComponent } from './add-primary-key-dialog/add-primary-key-dialog.component';
import { AddPrimaryKeyDialogService } from './add-primary-key-dialog/add-primary-key-dialog.service';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { AlertDialogService } from './alert-dialog/alert-dialog.service';
import { AskSupportDialogComponent } from './ask-support-dialog/ask-support-dialog.component';
import { AskSupportDialogService } from './ask-support-dialog/ask-support-dialog.service';
import { AutoMapFailedEventsDialogComponent } from './auto-map-failed-events-dialog/auto-map-failed-events-dialog.component';
import { AutoMapFailedEventsDialogService } from './auto-map-failed-events-dialog/auto-map-failed-events-dialog.service';
import { ChangePositionDialogComponent } from './change-position-dialog/change-position-dialog.component';
import { ChangePositionDialogService } from './change-position-dialog/change-position-dialog.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { ConfirmV2DialogComponent } from './confirm-v2-dialog/confirm-v2-dialog.component';
import { ConfirmV2DialogService } from './confirm-v2-dialog/confirm-v2-dialog.service';
import { DialogOverlayService } from './dialog-overlay.service';
import { ExecutionPolicyDialogReactComponent } from './execution-policy-dialog/execution-policy-dialog-react.component';
import { GenericDialogComponent } from './generic-dialog/generic-dialog.component';
import { JsonStructFieldsDialogReactComponent } from './json-struct-fields-dialog-react.component';
import { LogExpiryHandlerDialogComponent } from './log-expiry-handler-dialog/log-expiry-handler-dialog.component';
import { LogExpiryHandlerDialogService } from './log-expiry-handler-dialog/log-expiry-handler-dialog.service';
import { MultiTaskChangePositionDialogComponent } from './multi-task-change-position-dialog/multi-task-change-position-dialog.component';
import { MultiTaskChangePositionDialogService } from './multi-task-change-position-dialog/multi-task-change-position-dialog.service';
import { PipelinePermissionFailureDialogComponent } from './pipeline-permission-failure-dialog/pipeline-permission-failure-dialog.component';
import { QueryConfirmDialogReactComponent } from './query-confirm-dialog-react.component';
import { ReplayReasonDetailsDialog } from './replay-reason-details-dialog/replay-reason-details-dialog.component';
import { ReplayReasonDetailsDialogService } from './replay-reason-details-dialog/replay-reason-details-dialog.service';
import { ShareDocDialogService } from './share-doc-dialog/share-doc-dialog.service';
import { SourceBlockedDialogReactComponent } from './source-blocked-dialog-react.component';
import { UpgradeToLiveChatDialogComponent } from './upgrade-to-live-chat-dialog/upgrade-to-live-chat-dialog.component';
import { UpgradeToLiveChatDialogService } from './upgrade-to-live-chat-dialog/upgrade-to-live-chat-dialog.service';


const DIRECTIVES = [
  ConfirmDialogComponent,
  AlertDialogComponent,
  ReplayReasonDetailsDialog,
  LogExpiryHandlerDialogComponent,
  ChangePositionDialogComponent,
  AddPrimaryKeyDialogComponent,
  AddMemberDialogComponent,
  AutoMapFailedEventsDialogComponent,
  AskSupportDialogComponent,
  UpgradeToLiveChatDialogComponent,
  ConfirmV2DialogComponent,
  MultiTaskChangePositionDialogComponent,
  GenericDialogComponent,
  PipelinePermissionFailureDialogComponent,
  ExecutionPolicyDialogReactComponent,
  JsonStructFieldsDialogReactComponent,
  SourceBlockedDialogReactComponent,
  QueryConfirmDialogReactComponent
];

const DIALOG_SERVICES = [
  ConfirmDialogService,
  DialogOverlayService,
  AlertDialogService,
  ReplayReasonDetailsDialogService,
  LogExpiryHandlerDialogService,
  ChangePositionDialogService,
  AddPrimaryKeyDialogService,
  AutoMapFailedEventsDialogService,
  AskSupportDialogService,
  UpgradeToLiveChatDialogService,
  ConfirmV2DialogService,
  ShareDocDialogService,
  MultiTaskChangePositionDialogService
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ClipboardModule,
    OverlayModule,
    SnackbarModule,
    IpModule,
    HdA11yModule,
    SharedModule,
    PopupModule,
    TooltipModule,
    LoaderModule,
    MatSlideToggleModule,
    PipeModule,
    OwlMomentDateTimeModule,
    OwlDateTimeModule,
    HdFormModule,
    BadgeModule,
    HdAlertModule,
    HdMomentModule,
    SidelineModule,
    TrackingModule
  ],
  declarations: [ ...DIRECTIVES ],
  exports: [ ...DIRECTIVES ],
  entryComponents: [ ...DIRECTIVES ]
})
export class DialogModule {
  static forRoot(): ModuleWithProviders<DialogModule> {
    return {
      ngModule: DialogModule,
      providers: [ ...DIALOG_SERVICES ]
    };
  }
}
