import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guard/auth.guard';
import { EmailVerificationGuard } from '../core/guard/email-verification.guard';
import { EnterpriseEnabledGuard } from '../core/guard/enterprise-enabled.guard';
import { PostLoginDataUpdateGuard } from '../core/guard/post-login-data-update.guard';
import { TrialExpiryCheckGuard } from '../core/guard/trial-expiry-check.guard';
import { UserPersonaGuard } from '../core/guard/user-persona.guard';
import { AccountAccessResolver } from '../core/service/account-access.resolver';
import { MultiRegionResolver } from '../core/service/multi-region.resolver';
import { RecentEntitiesResolver } from '../core/service/recent-entities.resolver';
import { TeamDetailsResolver } from '../core/service/team-details.resolver';
import { TeamSettingsGuard } from '../core/guard/team-settings.guard';
import { AccountSuspendedReactComponent } from './account-suspended-react/account-suspended-react.component';
import { ActivationGuard } from '../core/guard/activation.guard';
import { CreateEntityRedirection } from './create-entity-redirection/create-entity-redirection';

import { DashboardComponent } from './dashboard.component';
import { DownloadPageReactComponent } from './download-page-react/download-page-react.component';
import { EmailVerificationReactComponent } from './email-verification-react/email-verification-react.component';
import { TrialExpiredReactComponent } from './trial-expired-react/trial-expired-react.component';
import { JobMonitoringReactComponent } from './job-monitoring-react/job-monitoring-react.component';
import { WorkflowGuard } from '../core/guard/workflow.guard';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [ AuthGuard, TeamSettingsGuard, PostLoginDataUpdateGuard ],
    resolve: {
      // recentEntities: RecentEntitiesResolver,
      teamDetails: TeamDetailsResolver,
      multiRegion: MultiRegionResolver,
      accountAccess: AccountAccessResolver
    },
    children: [
      {
        path: 'destination/edge',
        loadChildren: () => import('app/enterprise/destination/destination.module').then(m => m.DestinationModule),
        canActivate: [EnterpriseEnabledGuard, TrialExpiryCheckGuard, EmailVerificationGuard, UserPersonaGuard]
      },
      {
        path: 'pipeline/edge',
        loadChildren: () => import('app/enterprise/pipeline/pipeline.module').then(m => m.PipelineModule),
        canActivate: [EnterpriseEnabledGuard, TrialExpiryCheckGuard, EmailVerificationGuard, UserPersonaGuard]
      },
      {
        path: 'destination',
        loadChildren: () => import('app/destination/destination.module').then(m => m.DestinationModule),
        canActivate: [TrialExpiryCheckGuard, EmailVerificationGuard, UserPersonaGuard]
      },
      {
        path: 'pipeline',
        loadChildren: () => import('app/pipeline/pipeline.module').then(m => m.PipelineModule),
        canActivate: [TrialExpiryCheckGuard, EmailVerificationGuard, UserPersonaGuard]
      },
      {
        path: 'model',
        loadChildren: () => import('app/query/model.module').then(m => m.ModelModule),
        canActivate: [TrialExpiryCheckGuard, EmailVerificationGuard, UserPersonaGuard]
      },
      {
        path: 'workflow',
        loadChildren: () => import('app/workflow/workflow.module').then(m => m.WorkflowModule),
        canActivate: [TrialExpiryCheckGuard, EmailVerificationGuard, UserPersonaGuard, WorkflowGuard]
      },
      {
        path: 'activation',
        loadChildren: () => import('app/activate/activation.module').then(m => m.ActivationModule),
        canActivate: [ TrialExpiryCheckGuard, EmailVerificationGuard, UserPersonaGuard, ActivationGuard ]
      },
      {
        path: 'target',
        loadChildren: () => import('app/target/target.module').then(m => m.TargetModule),

        canActivate: [TrialExpiryCheckGuard, EmailVerificationGuard, UserPersonaGuard, ActivationGuard]
      },
      {
        path: 'account',
        loadChildren: () => import('app/account/account.module').then(m => m.AccountModule),
        canActivate: [UserPersonaGuard]
      },
      {
        path: 'team',
        loadChildren: () => import('app/team/team.module').then(m => m.TeamModule),
        canActivate: [UserPersonaGuard]
      },
      {
        path: 'payment',
        loadChildren: () => import('app/payment/payment.module').then(m => m.PaymentModule),
        canActivate: [UserPersonaGuard]
      },
      {
        path: 'alerts',
        loadChildren: () => import('app/notification-settings/notification-settings.module').then(m => m.NotificationSettingsModule),
        canActivate: [UserPersonaGuard]
      },
      {
        path: 'download/:feature/:filename',
        component: DownloadPageReactComponent
      },
      {
        path: 'trial-expired',
        component: TrialExpiredReactComponent
      },
      {
        path: 'account-suspended',
        component: AccountSuspendedReactComponent
      },
      {
        path: 'verify-email-block',
        component: EmailVerificationReactComponent
      },
      {
        path: 'create-entity-shortcut/:entity',
        component: CreateEntityRedirection,
      },
      {
        path: 'all-jobs',
        component: JobMonitoringReactComponent
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/pipeline'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
