import React, { useMemo, useState } from 'react';
import { takeUntil } from 'rxjs/operators';
import { DestinationService } from '../../../../../app/core/service/destination.service';
import { getErrorMessageFromObj } from '../../../../legacy-utils/request';
import {
  DESTINATION_CONFIG_WARNING_FIELDS,
  TRACKER_DEST_CONFIG_UPDATE_CANCEL,
  TRACKER_DEST_CONFIG_UPDATE_SUBMIT,
  TRACKER_DEST_CONFIG_UPDATE_SUBMIT_ERROR,
  TRACKER_DEST_CONFIG_UPDATE_SUBMIT_SUCCESS
} from '../../../../../app/nodes/destination-update-warning-dialog/constants';
import { DestinationConfigWarningFieldEnum } from '../../../../../app/nodes/destination-update-warning-dialog/model';
import RoundedIcon from '../../../../components/RoundedIcon';
import { HdIcon, HdIconButton, HdModal } from '../../../../components/UIElements';
import HdButton from '../../../../components/UIElements/HdButton';
import useAnalyticsTracker from '../../../../hooks/useAnalyticsTracker';
import useService from '../../../../hooks/useService';
import { getDataIdGenerator } from '../../../../utils/generateDataId';

interface DestinationUpdateWarningDialogProps {
  destinationUpdateWarningConfig: {
    visible: boolean;
    modifiedFields: any[];
    destinationFlatModel: any;
    destinationTypeIdentifier: string;
    skipErrors: boolean;
  };
  onClose: Function;
}

export function DestinationUpdateWarningDialog({
  destinationUpdateWarningConfig,
  onClose
}: DestinationUpdateWarningDialogProps) {
  return (
    <HdModal open={destinationUpdateWarningConfig.visible} styling={{ width: '420px' }}>
      <DestinationUpdateWarningDialogContent
        destinationUpdateWarningConfig={destinationUpdateWarningConfig}
        onClose={onClose}
      />
    </HdModal>
  );
}

const dataIdGenerator = getDataIdGenerator('destination-update-warning-dialog');

export function DestinationUpdateWarningDialogContent({
  destinationUpdateWarningConfig,
  onClose
}: DestinationUpdateWarningDialogProps) {
  const [ submitting, setSubmitting ] = useState(false);

  const { eventTrack } = useAnalyticsTracker();

  const destinationService = useService(DestinationService);

  const { title, message } = useMemo(() => {
    let tempTitle = '';
    let tempMessage = '';

    const modifiedFields = destinationUpdateWarningConfig.modifiedFields;
    const len = modifiedFields.length;
    let datasetChange = false;

    for (let i = 0; i < len; i++) {
      const warningField = modifiedFields[i];
      const destConfigWarningField = DESTINATION_CONFIG_WARNING_FIELDS[warningField];

      let titleFragment;
      if (i === 0) {
        titleFragment = destConfigWarningField.title;
      } else if (len > 2 && i < len - 1) {
        titleFragment = ', ' + destConfigWarningField.title;
      } else if (len > 1 && i === len - 1) {
        titleFragment = ' and ' + destConfigWarningField.title;
      }

      // If dataset changed then don't update description after that
      if (!datasetChange) {
        tempMessage = destConfigWarningField.description;
      }

      if (destConfigWarningField.value === DestinationConfigWarningFieldEnum.DATASET_NAME) {
        datasetChange = true;
      }

      tempTitle += titleFragment;
    }

    tempTitle += ' Changed';
    tempMessage += ' Are you sure you want to continue with the change?';

    return { title: tempTitle, message: tempMessage };
  }, [ destinationUpdateWarningConfig.modifiedFields ]);

  const onCancelClick = () => {
    eventTrack({
      action: TRACKER_DEST_CONFIG_UPDATE_CANCEL
    });

    onClose();
  };

  const onSubmitClick = () => {
    setSubmitting(true);

    eventTrack({
      action: TRACKER_DEST_CONFIG_UPDATE_SUBMIT
    });

    destinationService.editDestination(
      destinationUpdateWarningConfig.destinationFlatModel,
      destinationUpdateWarningConfig.destinationTypeIdentifier,
      destinationUpdateWarningConfig.skipErrors,
      true
    ).toPromise().then(() => {
      setSubmitting(false);

      eventTrack({
        action: TRACKER_DEST_CONFIG_UPDATE_SUBMIT_SUCCESS
      });

      onClose(true);

    }, (err) => {
      setSubmitting(false);

      eventTrack({
        action: TRACKER_DEST_CONFIG_UPDATE_SUBMIT_ERROR,
        properties: {
          error: getErrorMessageFromObj(err)
        }
      });

      onClose(err);
    });
  };

  return (<>
    <div className='dialog-header dialog-header--absolute'>
      <HdIconButton dataId={dataIdGenerator('close-btn')} onClick={() => onClose()}>
        <HdIcon name='close' />
      </HdIconButton>
    </div>

    <div className='dialog-body center-flex-col py-8 px-6'>
      <div className='center-flex-row justify-center mb-2'>
        <RoundedIcon containerBg='warning' iconName='warning' />
      </div>

      <span className='text-subheading-3 mb-5'>
        {title}
      </span>

      <div className='text-secondary text-center text-body-1 mb-7'>
        {message}
      </div>

      <div className='d-flex align-items-start'>
        <HdButton
          disabled={submitting}
          variation='outline'
          onClick={onCancelClick}
          dataId={dataIdGenerator('cancel')}
        >
          No, Don’t Change It
        </HdButton>

        <HdButton
          className='ml-3'
          disabled={submitting}
          showProgress={submitting}
          palette='error'
          onClick={onSubmitClick}
          dataId={dataIdGenerator('proceed')}
        >
          Yes, Change It
        </HdButton>
      </div>
    </div>
  </>);
}
