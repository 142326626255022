import React from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import { PlanChatSupportService } from '../../../../../../app/core/service/plan-chat-support.service';
import HdModal from '../../../../../components/UIElements/HdModal';
import { HdIcon, HdIconButton, HdRbacButton } from '../../../../../components/UIElements';
import { NodeLogo } from '../../../../../components/NodeLogo';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import useService from '../../../../../hooks/useService';
import { RawSourceType } from '../models';
import { RbacPermissions } from '../../../../../../app/core/models/user';
import { TRACKER_UPGRADE_PLAN_CLICK } from './constants';
import styles from './styles.module.scss';
import { SOURCE_TYPES } from '../../../../../../app/nodes/source-type/source-type';
import useHasPermission from '../../../../../hooks/useHasPermission';
import { getDataIdGenerator } from '../../../../../utils/generateDataId';

export interface SourceBlockedDialogProps {
  open: boolean;
  sourceType: RawSourceType;
  onClose: Function;
  dataId: string;
}

export const dataIdGenerator = getDataIdGenerator('sourceBlockedDialog');

export function SourceBlockedDialog({
  open,
  sourceType,
  onClose,
  dataId
}: SourceBlockedDialogProps) {
  const { isOwnerOrHasPermission } = useHasPermission();
  const planChatSupportService = useService(PlanChatSupportService);
  const history = useHistory();
  const analyticsTracker = useAnalyticsTracker();

  const onUpgradePlanClick = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_UPGRADE_PLAN_CLICK,
      properties: { 'Current Plan': planChatSupportService.currentPlanTier }
    });

    const nextUrl = encodeURIComponent(`${history.location.pathname}${history.location.search}`);
    history.replace(`/payment/plan-details?editing=true&next=${nextUrl}`);

    onClose();
  };

  return (
    <HdModal
      dataId={dataIdGenerator('modal')}
      open={open}
      onClose={() => {
        onClose();
      }}
      styling={{ maxWidth: 388 }}
    >
      <div className={styles.dialogBody}>
        <div className={styles.dialogCloseBtnWrapper}>
          <HdIconButton
            onClick={() => {
              onClose();
            }}
            dataId={dataId}
          >
            <HdIcon name='close' />
          </HdIconButton>
        </div>

        <div className='center-flex-row justify-center'>
          <div className='no-item-box m-0'>
            <NodeLogo
              size={7}
              className='mb-6 mt-3'
              logoURL={sourceType?.source_type_logo_url}
              darkModeLogoURL={sourceType?.source_type_dark_mode_logo_url}
              primaryColor={
                sourceType?.source_type_colour_code ||
                SOURCE_TYPES[sourceType?.source_type]?.primaryColor
              }
              darkModePrimaryColor={
                sourceType?.source_type_colour_code ||
                SOURCE_TYPES[sourceType?.source_type]?.darkModePrimaryColor
              }
              dataId={dataIdGenerator('nodeLogo')}
            />

            <span className='no-item-box-title text-bold'>
              {sourceType?.source_type_display} is not available in the Free plan
            </span>

            <span className='no-item-box-desc mb-6'>
              Upgrade to a paid plan to replicate data from this Source.
            </span>

            <HdRbacButton
              dataId={`${dataId}-upgradePlan`}
              rbacPermission={RbacPermissions.BILLING_EDIT}
              disabled={!isOwnerOrHasPermission(RbacPermissions.BILLING_EDIT)}
              disabledToolTipContent='Contact your workspace owner to upgrade your plan'
              onClick={onUpgradePlanClick}
            >
              Upgrade Plan
            </HdRbacButton>
          </div>
        </div>
      </div>
    </HdModal>
  );
}
